.new-aira .section-heading {
    font-size: 3.5rem;
    color: #000;
  }
  
  .new-aira .see-more-btn {
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 20px;
    padding: 5px 20px;
    color: #000;
  }
  
  .new-aira .custom-card {
    border-radius: 10px;
  }
  
  .new-aira .card-header {
    background: linear-gradient(90deg, #86A6E9 0%, #A067A8 50%, #ED6245 100%);
    border-radius: 10px 10px 0 0;
    color: #fff;
  }
  
  .new-aira .card-title {
    font-size: 1rem;
    color: #000;
  }
  
  .new-aira .card-images img {
    margin-right: 5px;
  }
  
  .new-aira .card-body {
    background: #fff;
    border-radius: 0 0 10px 10px;
  }
  
  .new-aira .card-text {
    color: #000;
  }
  
  .new-aira .card-footer {
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    border: none;
  }
  
  .new-aira .icon-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #D9D9D9;
    border: .5px solid #D9D9D9;
  }
  
  .new-aira .article-heading {
    color: #525252;
    font-size: 1.5rem;
  }
  
  .new-aira .read-article {
    text-decoration: none;
    color: #86A6E9;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }

/* NewAira.css */

.text-right {
  text-align: right !important;
}

.custom-card .card-images {
  display: flex;
  gap: 10px;
}

.custom-card .card-image {
  flex: 1;
}

.icon-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #D9D9D9;
}

.gradient-bg {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
}

.new-aira .section-heading {
  font-size: 3.5rem;
  color: #000;
}

.new-aira .see-more-btn {
  background-color: transparent;
  border: 2px solid #000;
}

.new-aira .custom-card {
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adding a bottom shadow */
}

.new-aira .card-header {
  background: linear-gradient(90deg, rgba(134, 166, 233, 0.3) 0%, rgba(160, 103, 168, 0.3) 50%, rgba(237, 98, 69, 0.3) 100%);
  border-radius: 10px 10px 0 0;
  color: #fff;
}

.new-aira .card-title {
  font-size: 1rem;
  color: #000;
}

.new-aira .card-images img {
  margin-right: 5px;
}

.new-aira .card-body {
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.new-aira .card-text {
  color: #000;
  font-size: 13px;
  font-weight: bold;
}

.new-aira .card-footer {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
}

.new-aira .icon-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #D9D9D9;
  border: .5px solid #D9D9D9;
}

.new-aira .article-heading {
  color: #525252;
  font-size: 1rem;
}

.new-aira .read-article {
  text-decoration: none;
  color: #86A6E9;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.middle-card .card-images img {
  margin-right: 10px; /* Adjusted margin for the middle card */
}

.middle-card .card-text {
  font-size: 16px; /* Increased font size for card text */
}

.middle-card .card-footer small {
  font-size: 14px; /* Increased font size for card footer */
}

.middle-card .section-heading {
  font-size: 4rem; /* Adjust the size as needed */
  color: #000;
}

.middle-card .article-heading {
  font-size: 2rem; /* Adjust the size as needed */
  color: #525252;
}

/* Media Queries for Responsive Font Sizes */
@media (max-width: 1200px) {
  .new-aira .section-heading {
    font-size: 3rem;
  }

  .new-aira .article-heading {
    font-size: 1.25rem;
  }

  .new-aira .card-title {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .new-aira .section-heading {
    font-size: 2.5rem;
  }

  .new-aira .article-heading {
    font-size: 1rem;
  }

  .new-aira .card-title {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .new-aira .section-heading {
    font-size: 2rem;
  }

  .new-aira .article-heading {
    font-size: 0.875rem;
  }

  .new-aira .card-title {
    font-size: 0.75rem;
  }
}

@media (max-width: 576px) {
  .new-aira .section-heading {
    font-size: 1.5rem;
  }

  .new-aira .article-heading {
    font-size: 0.75rem;
  }

  .new-aira .card-title {
    font-size: 0.65rem;
  }
}

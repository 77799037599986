.contact-us h2 {
  font-family: "Poppins", sans-serif;
  font-size: 3.5rem;
}

.contact-us p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #525252;
}

.contact-us {
  background-image: url('../assets/images/banner-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  padding-block: 84px;
}

.contact-us input,
.contact-us textarea {
  box-shadow: 0px 4px 16.5px rgba(235, 116, 114, 0.16);
  display: inline-block;
}

.contact-us form {
  padding-inline: 30%;
  padding-top: 50px;
}

.contact-us button {
  transition: 0.5s;
  background-size: 125%;
}

.contact-us button:hover {
  background-position: right center !important;
}

.contact-us .error {
  font-size: 1rem;
}

@media screen and (max-width : 439px) {
  .contact-us form {
    padding-inline: 0;
  }

  .contact-us {
    background-size: cover;
  }
}
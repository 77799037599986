/* Home.css */

.feature-highlight {
	background: linear-gradient(
		180deg,
		rgba(134, 166, 233, 0.2),
		rgba(160, 103, 168, 0.2),
		rgba(237, 98, 69, 0.2)
	) !important;
}

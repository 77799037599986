/* Testimonials.css */

.testimonials {
    background-color: #faf6f9;
    position: relative;
    z-index: -1;
}

.testimonials h2 {
    font-weight: 500;
    font-size: 30px;
}
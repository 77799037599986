/* NativelyBuilt.css */

.natively-built {
	padding: 4rem 2rem;
	text-align: center;
	background-image: url('../assets/images/banner-background.png');
	background-repeat: no-repeat;
	background-position: 30% 80%;
}

.natively-built h2 {
	font-size: 2.5rem;
	margin-bottom: 4rem;
	font-weight: 500;
}

.content-wrapper {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
}

.conversation-container {
	padding-inline: 25%;
}

.conversation-container p {
	margin: 0 !important;
	padding: 18px 40px;
	display: inline-block;
	border-radius: 10px;
}

.right-section p {
	color: white;
	background-color: #4289FF;
}

.left-section p {
	color: #525252;
	position: relative;
	text-align: start;
}

.left-section p::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #F6F0F6;
	opacity: 0.7;
	z-index: -1;
}

.button-container {
	width: 100%;
}

/* Shared Button Styles */
.button-container button {
	font-size: 21px;
	font-weight: 600;
	border-radius: 10px;
	border: none;
	padding: 14px 0;
	width: 100%;
	display: block;
}

/* Beta Testing Button */
.beta-btn {
	color: #474747;
	background: #FAFAFA;
	transition: 0.5s;
}

.beta-btn:hover {
	transform: translateY(5px);
}

/* Demo Button */
.demo-btn {
	color: white;
	margin-top: 14px;
	background-size: 120% auto;
	transition: 0.5s;
}

.demo-btn:hover {
	background-position: right center !important;
}

@media screen and (max-width: 439px) {
	.conversation-container {
		padding-inline: 0;
	}

	.conversation-container p {
		padding-inline: 24px;
	}
}
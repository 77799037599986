.hired-easily h2 {
	font-size: 2.5rem;
	font-weight: bold;
}

.hired-easily .card {
	border: none;
	background: none;
}

.hired-easily .card-title {
	font-size: 3.25rem;
	font-weight: bold;
	color: transparent;
	background: linear-gradient(
		90deg,
		var(--gradient-color-1) 0,
		var(--gradient-color-2) 10%,
		var(--gradient-color-3) 30%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.hired-easily .card-text {
	font-weight: 500;
	font-size: 1.4rem;
	color: #525252;
}

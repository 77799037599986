.pricing-column {
	border-radius: 12px;
	border: 1px solid #e1e1e1;
	padding: 1.5em;
	width: 100%;
	min-width: 280px;
	max-width: 295px;
	transition: border 0.1s ease-out, outline 0.1s ease-out;
}

.pricing-column.selected {
	outline: 3px solid #007bff; /* Highlight selected column */
	border-color: #007bff;
}

.pricing-grid .wrapper:nth-child(2) .pricing-column {
	background-color: #c8daff;
}

.pricing-grid .wrapper:nth-child(3) .pricing-column {
	background-color: #eeb7f6;
}

.pricing-grid .wrapper:nth-child(4) .pricing-column {
	background-color: #ffae9e;
}

.pricing-column .btn {
	border-radius: 12px;
	padding: 10px 18px;
	background-color: black;
	color: white;
	border: none;
}

.features li {
	margin: 14px 0;
}

/* Add-ons Section Styling */
.add-ons {
	padding: 12px 24px;
	border-radius: 12px;
	border: 1px solid #e1e1e1;
	transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
	opacity: 0;
	visibility: hidden;
}

.add-ons.show {
	opacity: 1;
	visibility: visible;
}

/* CSS Grid for Pricing Plan Columns */
.pricing-grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;
}

.pricing-grid .wrapper {
	display: flex;
	justify-content: center;
	padding: 8px 0;
}

@media (min-width: 768px) {
	.pricing-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 1200px) {
	.pricing-grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

.fadeout-horizontal {
  mask-image: linear-gradient(to right,
      transparent,
      white 2rem,
      white calc(100% - 2rem),
      transparent);
}

.marquee-container {
  color: white;
}

.marquee-text {
  overflow: clip;
}

.marquee-text-track {
  display: flex;
  padding-left: 5rem;
  gap: 5rem;
  width: max-content;
  animation: marquee-move-text var(--speed, 50s) linear infinite var(--direction, forwards);
}

.marquee-text p {
  padding-inline: 5rem;
  padding-block: 0.5rem;
  font-size: 15px !important;
}

.marquee-container:hover .marquee-text-track {
  animation-play-state: paused;
}

@keyframes marquee-move-text {
  to {
    transform: translateX(-50%);
  }
}
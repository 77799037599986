/* Header.css */
header {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
}

.btn-login {
	background: none;
	border: none;
	position: relative;
	padding: 8px 18px;
	border-radius: 8px;
	color: black;
	font-size: medium;
}

.btn-login::before {
	content: "";
	position: absolute;
	transition: background 0.5s;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	padding: 2px;
	/* Adjust padding as needed */
	background: var(--primary-gradient-90deg);
	/* Replace with your gradient */
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	mask-composite: exclude;
}

.btn-login:hover {
	color: #a067a8 !important;
}

.btn-login:hover::before {
	background: #a067a8 !important;
}

/* Overrides the rule in index.css */
.btn-login.btn:not(.btn-dark):hover {
	background: none;
}

.btn-try-free {
	transition: background 0.5s ease-in-out;
	-moz-transition: background 0.5s ease-in-out;
	-webkit-transition: background 0.5s ease-in-out;
	-o-transition: background 0.5s ease-in-out;

	background: var(--primary-gradient-90deg);
	background-size: 150% 100%;
	padding: 8px 18px;
	border: none;
	border-radius: 8px;
	color: white;
	font-size: medium;
}

.btn-try-free:hover {
	background-position: 100% 0;
	color: white !important;
}

.button-size {
	font-size: medium;
}

.align {
	align-items: center;
}

.navbar-toggler:focus {
	box-shadow: none;
}

@media screen and (max-width: 371px) {
	.btn-try-free {
		font-size: small !important;
	}

	.btn-login {
		font-size: small;
	}

	.button-size {
		font-size: smaller;
	}
}

@media screen and (max-width: 343px) {
	.btn-try-free {
		font-size: xx-small !important;
	}

	.btn-login {
		font-size: xx-small !important;
	}
}

a.btn-try-free:hover {
	background-color: black;
	border-radius: 8px;
	color: white;
}

.navbar-brand img {
	max-height: 50px;
	/* Adjust the height of your logo */
}

.nav-link {
	cursor: pointer;
	/* Ensures the cursor changes to a pointer on hover */
}

.nav-center {
	align-items: center;
	justify-content: center;
}
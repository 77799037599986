/* Testimonial.css */

.testimonial-card {
	background: white;
	border-radius: 16px;
	padding: 30px 40px;
	box-shadow: 0px 4px 16.5px rgba(235, 116, 114, 0.16);
	min-height: 300px;
	overflow: hidden;
}

.testimonial-card .company {
	color: #F06343;
}

.testimonial-card .avatar img {
	width: 50px;
}

.testimonial-card .avatar {
	flex-shrink: 0;
}
/* ========================== */
/* Root Variables */
/* ========================== */

:root {
	--primary-gradient-45deg: linear-gradient(45deg, #86a6e9, #a067a8, #ed6245);
	--primary-gradient-90deg: linear-gradient(90deg, #86a6e9, #a067a8, #ed6245);
	--primary-gradient-135deg: linear-gradient(135deg, #86a6e9, #a067a8, #ed6245);
	--gradient-color-1: #86a6e9;
	--gradient-color-2: #a067a8;
	--gradient-color-3: #ed6245;
	--button-border-radius: 16px;
}

/* ========================== */
/* Global Styles */
/* ========================== */

* {
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* ========================== */
/* Typography */
/* ========================== */

h1,
h2,
h3,
h4,
h5,
h6 {
	overflow: hidden;
}

.font-poppins {
	font-family: "Poppins", sans-serif;
}

.text {
	text-align: left !important;
	padding: 10px !important;
	margin-top: 0 !important;
}

.text-head {
	font-size: xx-large !important;
}

.text-size {
	font-size: 2.25rem !important;
}

.textSize {
	font-size: 23px !important;
}

.textSizeS {
	font-size: xx-small !important;
}

.description {
	text-align: left !important;
}

.description-text {
	font-size: x-small !important;
}

/* ========================== */
/* Buttons */
/* ========================== */



.btn {
	border-radius: var(--button-border-radius);
}

.btn-dark {
	background-color: #000;
	border: none;
	color: white !important;
	font-size: 1rem;
	/* Example: Adjust font size for buttons */
	padding: 10px 20px;
	/* Example: Adjust padding for buttons */
}

.btn-dark:hover {
	background-color: #333;
}

/* ========================== */
/* Links */
/* ========================== */

/* Smooth hover animations for <a> tags */
a {
	transition: color 0.3s ease;
}

a:hover {
	color: #8797a8 !important;
}

/* ========================== */
/* Cards */
/* ========================== */

.card-width {
	width: 100% !important;
	/* height: 1px; */
}

.cardTitle {
	display: flex;
	align-items: center;
	font-size: 1.25rem !important;
}

/* ========================== */
/* Sections */
/* ========================== */

.section-padding {
	padding: 4% 0 !important;
}

.section-width {
	width: 96% !important;
}

.content-sec {
	overflow: visible;
	z-index: 0;
	position: relative;
}

/* ========================== */
/* Icons */
/* ========================== */

.icon-posi {
	left: 48% !important;
	bottom: -30px !important;
}

.bottom-icon {
	bottom: -30px !important;
}

/* ========================== */
/* Headings */
/* ========================== */

.heading-div {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	padding: 58px;
	margin-top: 19px;
	font-weight: 300;
	white-space: break-spaces;
}

.head-med {
	margin-top: 39px !important;
}

/* ========================== */
/* Truncate Text */
/* ========================== */

.truncate-text {
	display: -webkit-box !important;
	width: 100% !important;
	-webkit-line-clamp: 3;
	overflow: hidden !important;
	-webkit-box-orient: vertical !important;
	height: 111px;
}

.truncate-text-head {
	height: 79px;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	width: 100% !important;
	overflow: hidden !important;
	-webkit-box-orient: vertical !important;
	height: 111px;
}

.truncate-text-desc {
	height: 200px !important;
}

.truncate-remove {
	display: "" !important;
	width: "";
	-webkit-line-clamp: 0;
	overflow: "" !important;
	-webkit-box-orient: "" !important;
	height: 198px !important;
}

/* ========================== */
/* Slick Slider */
/* ========================== */

.slick-slider {
	overflow: visible;
	height: 542px !important;
}

.slick-slide {
	padding: 12px;
}

.slick-arrow {
	cursor: pointer !important;
}

/* ========================== */
/* Footer */
/* ========================== */

.footer-container {
	transition: color 0.3s ease-in-out !important;
	cursor: pointer !important;
}

.footer-container:hover {
	background-color: #201e43 !important;
}

/* ========================== */
/* Miscellaneous */
/* ========================== */

.aira-background-gradient {
	background: var(--primary-gradient-90deg);
}

.overflow-vis {
	overflow: visible;
}

.overflow-hid {
	overflow: hidden !important;
}

.pointer {
	cursor: pointer;
}

.content-image {
	height: 228px !important;
	overflow: hidden;
}

.box-c {
	justify-content: space-evenly !important;
	align-items: center !important;
	height: 512px !important;
	overflow: hidden !important;
}

.subtext {
	color: #A067A8;
	background-color: #D9D2E9;
	padding: 15px 30px !important;
	width: fit-content !important;
}

/* ========================== */
/* Media Queries */
/* ========================== */

@media screen and (max-width: 769px) {
	.cardTitle {
		display: flex;
		align-items: center;
		font-size: 1.25rem !important;
	}

	.text-size {
		font-size: 2.25rem !important;
	}
}

@media screen and (max-width: 895px) {
	.textSize {
		font-size: 23px !important;
	}
}

@media screen and (max-width: 525px) {
	.textSize {
		font-size: 11px !important;
	}

	.textSizeS {
		font-size: xx-small !important;
	}
}

@media screen and (max-width: 604px) {
	.section-padding {
		padding: 6% 0 !important;
	}
}

@media screen and (max-width: 1024px) {
	.icon-posi {
		left: 46% !important;
		bottom: -34px !important;
	}
}

@media screen and (max-width: 768px) {
	.icon-posi {
		width: 59px !important;
		bottom: -39px !important;
	}
}

@media screen and (max-width: 649px) {
	.icon-posi {
		width: 45px !important;
		bottom: -40px !important;
		left: 45% !important;
	}
}

@media screen and (max-width: 407px) {
	.icon-posi {
		width: 39px !important;
		bottom: -44px !important;
	}
}

@media screen and (max-width: 718px) {
	.heading-div {
		padding: 27px !important;
		margin-top: 40px !important;
	}

	.text-head {
		font-size: xx-large !important;
	}
}

@media screen and (max-width: 475px) {
	.heading-div {
		padding: 6px !important;
	}

	.text-head {
		font-size: x-large !important;
	}
}

@media screen and (max-width: 439px) {
	.planSize {
		font-size: 7px !important;
	}
}

@media screen and (min-width: 1440px) {
	.truncate-remove {
		display: "" !important;
		width: "";
		-webkit-line-clamp: 0;
		overflow: "" !important;
		-webkit-box-orient: "" !important;
		height: 198px !important;
	}

	.h2,
	h2 {
		font-size: 2rem !important;
	}

	.truncate-text-head {
		height: 88px !important;
	}
}

@media screen and (max-width: 1430px) {
	.description-text {
		font-size: x-small !important;
	}

	.truncate-text-head {
		margin-top: 44px !important;
		font-size: 20px !important;
		height: 53px !important;
	}
}

@media screen and (max-width: 1077px) {
	.truncate-text {
		height: auto !important;
		margin-bottom: 0 !important;
	}

	.description {
		font-size: 1rem !important;
	}
}

@media screen and (min-width: 100px) {
	.slick-slider {
		height: 542px !important;
	}

	.box-c {
		height: 512px !important;
		overflow: hidden !important;
	}
}
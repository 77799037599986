/* FeaturesHighlight.css */

.features-highlight .feature p {
	font-size: 20px;
}

.features-highlight .feature h1,
.features-highlight .feature h2,
.features-highlight .feature h3 {
	font-size: 41px;
}

.features-highlight .gradient-text {
	background: linear-gradient(90deg, var(--gradient-color-1), var(--gradient-color-2), var(--gradient-color-3));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.features-highlight * {
	overflow: hidden;
	/* Important for animations to appear smooth */
}

.features-highlight .fh-card {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px inset;
}

.features-highlight .fh-header {
	padding: 24px;
	cursor: pointer;
}

.features-highlight .fh-title {
	color: #3c3c3c;
}

.features-highlight .fh-header .fh-title {
	/* h5 size */
	font-size: clamp(18px, 3vw, 22px) !important;
	transition: font-size 0.7s ease, transform 0.7s ease;
}

.features-highlight .fh-header.open {
	padding-bottom: 32px;
}

.features-highlight .fh-header.open .fh-title {
	/* h2 size */
	transform: translateY(30px);
	font-size: clamp(24px, 5vw, 32px) !important;
}

.features-highlight .fh-body {
	max-height: 0;
	overflow: hidden;
	padding: 0;
	transition: padding 0.5s ease, transform 0.7s ease, max-height 0.7s ease;
}

.features-highlight .fh-body.open {
	max-height: 1200px;
	transform: translateY(-30px);
	padding: 24px;
}

.features-highlight .fh-content {
	margin-top: 50px;
}

.features-highlight .fh-content .fh-title {
	display: none;
}

.features-highlight .accurate-data-extraction {
	margin-top: 8px;
	border-radius: 24px 24px 0 0;
	background-color: #ffffff;
}

.features-highlight .research {
	background-color: #CAD7F2;
}

.features-highlight .contextual-memory {
	background-color: #B8BFE9;
}

.features-highlight .ai-assistant {
	background-color: #9483C5;
}

.features-highlight .confusion {
	background-color: #9D70B1;
	border-radius: 0 0 10px 10px;
}

.features-highlight .plus-icon {
	margin-right: 34px;
	transition: margin-right 0.3s ease;
}

.features-highlight .minus-icon {
	margin-right: 28px;
	transition: margin-right 0.3s ease;
}

@media screen and (max-width: 439px) {
	.features-highlight .plus-icon {
		margin-right: 10px;
	}

	.features-highlight .minus-icon {
		margin-right: 5px;
	}

	.features-highlight .fh-body.open {
		transform: none;
	}

	.features-highlight .fh-header.open {
		justify-content: flex-end !important;
	}

	.features-highlight .fh-header.open .fh-title {
		display: none;
	}

	.features-highlight .fh-content {
		margin-top: 0;
	}

	.features-highlight .fh-content .fh-title {
		display: block;
	}
}
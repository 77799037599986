/*popup css	*/
.popOverlay {
    background-color: #ffffff80;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
      align-items: center;
  }
  .popWrp {
    background-color: #FFFFFF;
    display: flex;
    width: 55%;
    padding: 2%;
    border-radius: 27px;
    position: absolute;
    z-index: 99;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins, sans-serif;
      position: relative;
  }
      .close {
      position: absolute;
      z-index: 99;
      top: 19px;
      left: 21px;
  }
  .lftWrp {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 50%;
    min-height: 500px;
  }
  .rqs {
    font-size: 36px;
    font-weight: 600;
  }
  .emal {
    border-bottom: 2px solid #818B98;
    width: 95%;
    line-height: 31px;
    font-size: 17px;
  }
  input {
    border: 0;
  }
  .chkwrp {
    color: #B0AEB0;
    margin: 31px 0;
  }
  .btnCon {
    border: 0;
    background: none;
    margin-top: 27px;
    width: 26%;
  }	
      
  .checkcontainer {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 104%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
      .checkcontainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  }
      
      
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 5px;
  }	
      
      .checkcontainer input:checked ~ .checkmark {
      background-color: #86A6E9;
  }
      
      .checkcontainer input:checked ~ .checkmark::after {
      display: block;
  }
      .checkmark::after {
      content: "";
      position: absolute;
      display: none;
  
  }
      .checkcontainer .checkmark::after {
      left: 6px;
      top: -1px;
      width: 7px;
      height: 12px;
              content: '✓';
      color: #fff;
      font-size: 19px;
      font-weight: bolder;
  
  }
      
      .rgtWrp {
    width: 50%;
  }
      .rgtWrp img {
    width: 100%; height: auto;
  }
      
  
      
  .lstrow {
      font-size: 99%;
  }	
  .btnCon img {
      width: 100%;
  }	
  
      
      
   @media (max-width: 1439px) {
       .popWrp {
      width: 80%;
  }
     
  }
  
   @media (max-width: 720px) {
     .lftWrp, .rgtWrp {
    width: 98%;
  }
  .popWrp {
    flex-direction: column;
  } 
  }
/* HeroBanner.css */

.hero-banner {
	text-align: center;
	font-family: "Poppins", sans-serif;
	background-image: url('../assets/images/banner-background.png');
	background-repeat: no-repeat;
	background-position: 65% -60%;
}

.hero-banner h1 {
	font-size: 52px;
}

.feature-button {
	background: none;
	border: 1px solid #86a6e9;
	color: #25233f;
	font-size: 1.2rem;
	border-radius: 14px;
}

.description {
	font-size: 1.2rem;
	color: #525252;
}

.description .text-bold {
	font-weight: bold;
}

.badge-gradient {
	background: var(--primary-gradient-90deg);
	color: white;
	border-radius: 12px;
	padding: 4px 12px;
	/* Example: Adjust padding for gradient badge */
	font-size: 0.9rem;
	/* Adjust font size as needed */
}

.hero-banner button {
	font-size: 24px;
	color: white;
	font-weight: bold;
	border: 4px solid white;
	border-radius: 50px;
	width: 294px;
	transition: 0.5s;
	background: linear-gradient(90deg, #86a6e9, #a067a8, #ed6245);
	background-size: 120% auto;
}

.hero-banner button:hover {
	cursor: pointer;
	background-position: -52px center !important;
	color: white !important;
}

.hero-banner .aira-sample {
	width: inherit;
}

/* Responsive Font Sizes */

@media (max-width: 768px) {
	h1 {
		font-size: 2.5rem;
	}

	.description {
		font-size: 1rem !important;
	}

	.btn-dark {
		font-size: 0.9rem;
		padding: 8px 16px;
	}
}
.backblog {
  background-color: #160e2c;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

.container-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 26px 0 55px; */
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
}

.content-section {
  align-self: stretch;
  display: flex;
  gap: 18px;
  margin: 0 14px 25px;
}

.section-highlight {
  border-radius: 17px;
  background: linear-gradient(90deg,
      rgba(134, 166, 233, 0.7) 0%,
      rgba(160, 103, 168, 0.7) 50%,
      rgba(237, 98, 69, 0.7) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  padding: 160px 0;
  position: relative;
}

.section-content {
  z-index: 10;
  display: flex;
  width: 80%;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.image-width {
  width: 80vw;
  border-radius: 11px;
}

.title {
  color: #fff;
  align-self: stretch;
  font: 600 60px Poppins, sans-serif;
  text-align: center;
}

.subtitle {
  color: #fff;
  text-align: center;
  margin-top: 36px;
  font: 500 20px Poppins, sans-serif;
}

.icon-container {
  height: 61px;
  padding: 0 4px;
  position: absolute;
  bottom: 0px;
  overflow: hidden;
}

@media screen and (max-width : 556px) {
  .contentSection {
    height: 268px !important;
  }
}

@media screen and (max-width : 455px) {
  .contentSection {
    height: 296px !important;
  }
}

.icon {
  aspect-ratio: 1.02;
  object-fit: auto;
  object-position: center;
  width: 100%;
}

.divider {
  border-radius: 17px;
  background-color: #d9d9d9;
  align-self: start;
  width: 6px;
  height: 369px;
}

.main-content {
  /* margin-top: 49px; */
  width: 100%;
  /* max-width: 1228px; */
  overflow: hidden;
}

.content-h {
  height: 500px !important;
}

.content-row {
  gap: 20px;
  display: flex;
}

.column-half {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  overflow: hidden;
  height: 100% !important;
}

.width-style-image {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  /* padding: 14px; */
}

.column-half img {
  border-radius: 5%;
}

.text-content {
  display: flex;
  margin-top: 11px;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  padding: 0 20px;
}

.badge-ai {
  border-radius: 4px;
  background-color: rgba(134, 166, 233, 0.8);
  width: 36px;
  align-items: start;
  white-space: nowrap;
  justify-content: center;
  padding: 8px 10px;
  font: 16px Poppins, sans-serif;
}

.headline {
  margin-top: 21px;
  font: 182% Poppins, sans-serif;
}

.headline a {
  text-decoration: none;
}

.description {
  margin-top: 37px;
  font: 110% Poppins, sans-serif;
}

.description-text {
  font-size: x-large;
}

.column-half-right {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
}

.highlight-box {
  border-radius: 24px;
  background-color: #86a6e9;
  width: 611px;
  max-width: 100%;
  height: 345px;
  margin: 0 auto;
}

.secondary-content {
  margin-top: 140px;
  width: 100%;
  max-width: 1227px;
}

.secondary-row {
  gap: 20px;
  display: flex;
}

.column-large {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 54%;
  margin-left: 0px;
}

.column-small {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 46%;
  /* margin-left: 20px; */
  /* justify-content: center; */
  height: inherit;
}

.review-section {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  padding: 0 20px;
}

.badge-review {
  border-radius: 4px;
  background-color: rgba(160, 103, 168, 0.8);
  align-self: start;
  white-space: nowrap;
  justify-content: center;
  padding: 8px 10px;
  font: 16px Poppins, sans-serif;
}

.review-headline {
  margin-top: 21px;
  font: 182% Poppins, sans-serif;
}

.review-description {
  margin-top: 36px;
  font: 110% Poppins, sans-serif;
}

.footer-container {
  border-radius: 51px;
  background-color: #201835;
  margin-top: 49px;
  padding: 5% 5%;
  width: 100%;
}

.footer-row {
  gap: 20px;
  display: flex;
}

.footer-column {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  color: #fff;
  font-weight: 500;
}

.badge-software {
  border-radius: 4px;
  background-color: #ed6245;
  align-self: start;
  white-space: nowrap;
  justify-content: center;
  padding: 8px 10px;
  font: 16px Poppins, sans-serif;
}

.footer-headline {
  /* margin: 18px 32px 0 0; */
  padding: 22px;
  font: 182% Poppins, sans-serif;
  text-align: left;
}

.footer-headline a {
  text-decoration: none;
}

.footer-description {
  font-family: Poppins, sans-serif;
  line-height: 29px;
  margin-top: 27px;
  font-size: 110%;
  text-align: left;
  padding: 22px;
  white-space: break-spaces;
}

.footer-description-more {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 29px;
  margin-top: 45px;
}

.footer-column-right {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
}

.explore-more {
  color: #fff;
  text-align: center;
  /* margin-top: 108px; */
  font: 500 40px Poppins, sans-serif;
}

.explore-section {
  z-index: 10;
  margin-top: 35px;
  width: 100%;
  max-width: 1440px;
  padding: 0 46px;
  height: 681px;
}


.explore-row {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

@media screen and (max-width : 1024px) {
  .explore-row {
    flex-direction: column;
  }
}

.column-third {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 0px;
}

.explore-box {
  border-radius: 23px;
  background-color: #201835;
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  padding: 4%;
  height: 100%;
  justify-content: space-between;
}

.explore-title {
  align-self: start;
  /* margin-left: 19px; */
  text-align: left;
  padding: 22px;
  font: 500 25px Poppins, sans-serif;
}

.explore-title a {
  text-decoration: none;
}

.explore-description {
  margin: 23px 22px 0 19px;
  font: 400 17px Poppins, sans-serif;
  height: 108px;
  text-align: left;
}

.explore-extra-content {
  border-radius: 10px;
  margin-top: 21px;
  /*! height: 214px;
    */
}

.column-third-right {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
}

.explore-more-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #fff;
}

.additional-box {
  border-radius: 23px;
  background-color: #201835;
  display: flex;
  flex-direction: column;
  padding: 22px 14px;
}

.additional-title {
  align-self: start;
  margin-left: 19px;
  font: 500 25px Poppins, sans-serif;
}

.additional-description {
  margin: 24px 22px 0 19px;
  font: 400 17px Poppins, sans-serif;
}

.additional-content {
  border-radius: 10px;
  background-color: #a067a8;
  margin-top: 45px;
  height: 214px;
}

.view-more-button {
  border-radius: 90px;
  background-color: #2b3f6c;
  align-self: center;
  margin-top: 34px;
  width: 161px;
  max-width: 100%;
  padding: 20px 33px;
  font: 400 17px Poppins, sans-serif;
  cursor: pointer;
  margin: 41px auto 0;
  color: #fff;
  text-align: center;
}

.view-button {
  margin: 19px auto 0 !important;
  text-decoration: none;
  /* margin-top: 10px; */
}

.column-third-left {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
}

.community-box {
  border-radius: 23px;
  background-color: #201835;
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  padding: 22px 13px;
}

.community-title {
  align-self: start;
  margin-left: 19px;
  font: 500 25px Poppins, sans-serif;
}

.community-description {
  margin: 17px 21px 0 19px;
  font: 400 17px Poppins, sans-serif;
}

.community-content {
  border-radius: 10px;
  background-color: #f06343;
  margin-top: 20px;
  height: 214px;
}

.logo {
  aspect-ratio: 1.61;
  object-fit: auto;
  object-position: center;
  width: 13px;
  stroke-width: 1.5px;
  stroke: #fff;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 2px;
  margin-top: -4px;
}

.social-follow {
  background-color: #070311;
  align-self: stretch;
  display: flex;
  margin-top: 89px;
  width: 100%;
  flex-direction: column;
  font-size: 97%;
  font-weight: 500;
  padding: 23px 0 6px;
}

.social-icons {
  display: flex;
  margin-left: 34px;
  width: 251px;
  max-width: 100%;
  flex-direction: column;
  color: #fff;
}

.icons-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.follow-text {
  font-family: Poppins, sans-serif;
  align-self: stretch;
  margin: auto 0;
}

.social-icon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 25px;
  fill: #d9d9d9;
  align-self: stretch;
}

.social-icon-medium {
  aspect-ratio: 1.23;
  object-fit: auto;
  object-position: center;
  width: 26px;
  fill: #d9d9d9;
  align-self: stretch;
  margin: auto 0;
}

.bottom-border {
  background-color: #fff;
  min-height: 1px;
  margin-top: 10px;
  width: 99.8%;
}

.footer {
  align-self: center;
  display: flex;
  /* margin: 46px 0; */
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  letter-spacing: -0.68px;
}

.footer-links {
  align-self: start;
  display: flex;
  gap: 20px;
  color: #fff;
}

.footer-link {
  font-family: Poppins, sans-serif;
}

.footer-secondary {
  display: flex;
  gap: 20px;
}

.footer-help {
  display: flex;
  gap: 12px;
  white-space: nowrap;
}

.footer-content {
  display: flex;
  width: 95%;
  gap: 20px;
  justify-content: space-between;
  padding: 0 2.5%;
}

.footer-help-icon {
  font-family: Poppins, sans-serif;
  background-color: #d9d9d9;
  border-radius: 50%;
  color: #201835;
  text-align: center;
  width: 22px;
  height: 22px;
}

.footer-help-text {
  color: #fff;
  font-family: Poppins, sans-serif;
}

.footer-language {
  display: flex;
  gap: 20px;
  color: #fff;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font-family: Poppins, sans-serif;
}

.flag-icon {
  aspect-ratio: 1.67;
  object-fit: auto;
  object-position: center;
  width: 10px;
  fill: #d9d9d9;
  margin: auto 0;
}

.footer-logo {
  width: 72px;
}

.explore-extra-content img {
  width: 100%;
  height: auto;
}

.column-half.left {
  order: -1;
}

@media (max-width: 1439px) {
  .container {
    width: 98%;
  }

  .section-highlight {
    padding: 9% 0;
  }

  .title {
    font-size: 254%;
    text-align: center;
  }

  .subtitle {
    margin-top: 3%;
    font-size: 99%;
  }

  .explore-description {
    font-size: 80%;
    height: 20%;
  }

  .view-more-button {
    padding: 1%;
    font-size: 97%;
  }
}

@media (max-width: 1077px) {
  .content-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .column-half {
    width: 98%;
  }

  .column-half.left {
    order: initial;
  }

  .footer-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .column-large,
  .column-small {
    width: 98%;
  }
}

@media (max-width: 650px) {
  .explore-row {
    flex-wrap: wrap;
    font-size: 173%;
    justify-content: center;
  }

  .col.column-third {
    width: 96%;
  }
}

@media screen and (max-width : 665px) {
  .titleSize {
    font-size: 24px;
  }
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
/* Footer.css */

.footer {
	background-color: #F2F2F2;
	margin-top: 0;
}

.footer-logo {
	width: 90px;
}

.footer-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	/* Display the menu items inline */
	justify-content: space-evenly;
	gap: 15px;
	/* Space between menu items */
}

.footer-menu li {
	margin: 0;
}

.footer-menu a {
	text-decoration: none;
	color: #000;
}

.footer-divider {
	border: none;
	height: 2px;
	/* Set the height of the divider */
	background: linear-gradient(to right,
			var(--gradient-color-1),
			var(--gradient-color-2),
			var(--gradient-color-3));
}

/* Footer Social Icons */
.social-icon i {
	font-size: 1rem;
	/* Adjust icon size as needed */
	color: #000;
	width: 40px;
	/* Ensure the icon is within a 40px circle */
	height: 40px;
	/* Ensure the icon is within a 40px circle */
	border: 1px solid #000;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
	text-decoration: none;
	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.social-icon i path {
	transition: fill 0.3s ease-in-out;
}

.social-icon i:hover {
	background-color: black;
	color: #f8f9fa;
}

.social-icon i path {
	fill: black;
}

.social-icon i:hover path {
	fill: #f8f9fa;
}

.social-icon i svg {
	width: 100%;
}

.right-side-info * {
	text-decoration: none;
	margin: 0;
}

.contact-info * {
	color: #525252;
}

.privacy-terms {
	margin-left: 10px;
	color: #000;
	text-decoration: none;
}
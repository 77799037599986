/* ReadyStarted.css */

.ready-started {
	background-color: #F2F2F2;
}

.ready-started .ready-title {
	font-size: 1.5rem;
	/* Adjust font size as needed */
	font-weight: bold;
	color: #000;
}

.ready-started .ready-description {
	font-size: 1rem;
	color: #000;
}

.ready-started .btn-book-demo,
.ready-started .btn-contact-us {
	width: 200px;
	height: 48px;
	border-radius: 8px;
}

.ready-started .btn-book-demo {
	background: linear-gradient(90deg,
			var(--gradient-color-1),
			var(--gradient-color-2),
			var(--gradient-color-3));
	color: white;
	background-size: 120% auto;
	transition: background 0.5s;
}

.ready-started .btn-book-demo:hover,
.ready-started .btn-book-demo:hover {
	cursor: pointer;
}

.ready-started .btn-book-demo:hover {
	cursor: pointer;
	background-position: right center !important;
}


.ready-started .btn-contact-us {
	position: relative;
	background: none;
}

.btn-contact-us::before {
	content: "";
	position: absolute;
	transition: background 0.5s;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
	padding: 2px;
	/* Adjust padding as needed */
	background: var(--primary-gradient-90deg);
	/* Replace with your gradient */
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	mask-composite: exclude;
}

.btn-contact-us:hover {
	color: #a067a8 !important;
}

.btn-contact-us:hover::before {
	background: #a067a8 !important;
}
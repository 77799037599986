/* VideoSection.css */
.video-section {
	padding: 2rem 0;
	/* Add some padding to the section */
	padding-top: 0;
}

.player-wrapper {
	position: relative;
	padding-top: 56.25%;
	/* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 6px;
}

/* For the youtube video player */
.react-player>div {
	overflow: hidden;
}